<template>
    <div class="table-responsive results">
        <table id="results_set" class="table table-hover">
            <thead>
            <tr>
                <th v-for="(field, id) in state.search.resultFields" v-bind:key="id">{{ field.header }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in state.search.results.rows" v-bind:key="index">
                <td v-for="(field, id) in state.search.resultFields" v-bind:key="id">
                    <div :class="field.class">
                    <a v-if="field.link == 'download'" target="_blank" :href='`${$cdiVars.API_URL}/download/file/${row[field.hrefId]}?token=${fileAccessToken}`'>{{ row[id] }}</a>
                    <a v-else-if="field.link === 'external'" target="_blank" :href='row[id]'>{{ (row[field.displayField]) ? row[field.displayField] : row[id] }}</a>
                    <router-link v-else-if="field.link" :to="{ name: field.link, params: { 'id': row[id] }}">
                        {{ field.textField ? row[field.textField] : row[id] }}
                    </router-link>
                    <span v-else>{{ row[id] }}</span>
                    </div>
                </td>
            </tr>
            <tr v-if="state.search.results.more_count > 0">
                <th colspan="100%">
                    <span class="cdi_link" @click="searchMore">{{ state.search.results.more_count }} More {{ state.titlePlural }}</span>
                </th>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import fields from "../../reports/utils/Fields.vue";

export default {
    name: "SearchResult",
    data() {
        return {
            state: store.state,
        }
    },
    methods: {
        searchMore: function() {
            this.state.search.formFields.searchIndex.val = this.state.search.results.more_index;
            store.searchMore(store.state.search.endPoint, this.state.search.formFields);
        },
    },
    computed: {
        fields() {
            return fields
        },
        fileAccessToken: function () {
            return this.$appStore.getUserFileAccessToken();
        }
    }
}
</script>

<style scoped>
.limit_width_expand_on_hover{
    max-width: 50px!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.limit_width_expand_on_hover:hover{
    position: absolute;
    z-index: 1000!important;
    max-width: none!important;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-overflow: unset;
    white-space: unset;
    padding: 2px;
}
</style>